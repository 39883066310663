<template>
    <div class="icon_container">

        <div class="container">

            <div class="row">

                <div class="col-md-6 col-sm-6 col-xs-12">

                    <div class="col-md-4">

                        <div class="row">



                        </div>

                    </div>

                    <div class="col-md-8">

                        <div class="row">

                            <div class="col-md-2"><i class="fas fa-truck fa-3x pull-right"></i></div>

                            <div class="col-md-10"><strong>Delivery System</strong>

                                <br>On All Orders</div>

                        </div>

                    </div>



                </div>

                <div class="col-md-6 col-sm-6 col-xs-12">

                    <div class="col-md-4">

                        <div class="row">

                        </div>

                    </div>

                    <div class="col-md-8">

                        <div class="row">

                            <div class="col-md-2"><i class="fas fa-gift fa-3x pull-right"></i></div>

                            <div class="col-md-10"><strong>Click Here for Wholesale</strong>

                                <br>Wholesale List

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>
</template>
<script>
    export default {
        name: 'ServiceMenu',
        props: {
            msg: String
        }
    }
</script>