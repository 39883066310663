<template>
  <!-- HEADER -->
  <div class="topBar">

    <div class="container">

      <div class="row">

        <div class="col-md-3 col-sm-7 col-xs-7">

          <div class="navbar-header">

            <!--<a class="navbar-brand" href="https://eurasiasupplies.com/">-->
            <router-link class="navbar-brand" :to="{ name: 'home' }">
              <img class="img-responsive" :src="'/eurasia/img/logo/logo.png'" alt="logo">
            </router-link>

            <!--<img class="img-responsive" :src="'/eurasia/img/logo/logo.png'" alt="logo">-->

            <!--</a>-->

          </div>

        </div>

        <div class="col-md-6  col-sm-6 col-xs-12" style="padding-top: 22px;">

          <form @submit.prevent="goToSearchPage(productSearch)" name="frm_sitesearch_form" accept-charset="utf-8"
            method="get">

            <div class="input-group">

              <input type="text" name="q" v-model="productSearch"
                class="form-control input_search1 ui-autocomplete-input" id="search_product_auto" placeholder="Search">

              <div class="input-group-btn">

                <button class="btn btn-default sreach-button" type="submit">

                  <i class="fas fa-search srch-icon"></i>

                </button>

              </div>

            </div>
            <div v-if="searchItem" class="search-item">
              <ul v-if="productBySearch.length > 0">
                <li v-for="product in productBySearch" :key="product.id">
                  <router-link :to="{ name: 'product', params: { slug: product.slug, type: product.product_type } }">
                    {{ product.name }}
                  </router-link>
                </li>
              </ul>
            </div>

          </form>

        </div>

        <div class="col-md-3  col-sm-6 col-xs-12">

          <ul class="list-inline pull-left top-right hover" style="padding-top: 13px;">
            <li v-if="!accessToken">
              <router-link :to="{ name: 'login' }">Login</router-link>
            </li>
            <li v-if="accessToken">
              <router-link :to="{ name: 'customer_dashboard' }">{{ customer.customer.name }}</router-link>
            </li>
            <li v-if="accessToken" @click="logout" style="cursor: pointer"><a>Logout</a></li>
            <!--<pre>{{accessToken}}</pre>
            <pre>{{customer}}</pre>-->
            <li v-if="!accessToken">
              <router-link :to="{ name: 'registration' }">Register</router-link>
            </li>

            <li class="cart-dropdown" id="product_cart"><a href="#" data-toggle="modal" data-target="#myModal2"
                class="dropdown-toggle">

                <i class="fa fa-shopping-cart"></i> <span class="cart-box" id="cart-info" title="View Cart"> ({{
                  shoppingBag.length }}) Item </span> </a>
            </li>

          </ul>


        </div>

      </div>

    </div>

    <!--    Sidebar Cart-->
    <div data-toggle="modal" data-target="#myModal2" class="right-fixed-cart">
      <i class="fa fa-shopping-cart"></i>
      <p class="right-fixed-cart-total">৳{{ total }}</p>
    </div>
    <!---------------------------------------------Cart list modal------------------------------------------------------------------->

    <!-- Modal -->
    <div class="modal right fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span>
            </button>

            <!--h4 class="modal-title" id="myModalLabel2">Right Sidebar</h4-->


          </div>
          <div class="modal-body" id="product_listing">
            <!--h3 class="pageHead" style="margin-top:0px;"></h3-->
            <div class="new_fullpage_box" style="margin-bottom:25px;"><!-- begin:page_Inner -->
              <form name="frm_mycart_form" method="post" action="https://eurasiasupplies.com/products/updatecart/">
                <div class="row">
                  <div id="no-more-tables" class="table_overflow">
                    <table class="table-bordered table-striped table-condensed cf" border="0" cellspacing="1"
                      cellpadding="8">
                      <thead class="cf">
                        <tr class="tableHead" bgcolor="#d1d6dc">

                          <td class="black12bold paddingleft10" align="center" width="15%" height="30">
                            Picture
                          </td>

                          <td class="black12bold numeric" align="left" width="35%">Product Name</td>
                          <td class="black12bold numeric" width="2%" align="center">QTY</td>

                          <td class="black12bold numeric" width="8%" align="center">Price</td>

                          <td class="black12bold numeric" width="2%" align="center"></td>
                        </tr>

                      </thead>

                      <tr v-if="shoppingBag.length === 0">
                        <td colspan="6" class="black12bold numeric">
                          <p style="margin-bottom:5px;">There is no product into your cart.</p>
                        </td>
                      </tr>
                      <tr v-if="shoppingBag.length > 0" v-for="bag in shoppingBag">
                        <!--                        <pre>{{bag}}</pre>-->
                        <td class="black12bold numeric">
                          <!--                          <pre>{{bag}}</pre>-->
                          <img style="height: 40px; margin: 5px 0px"
                            :src="'https://eurasiasupplies.com/euadmin/' + bag.cartImage" alt="">
                        </td>
                        <td class="black12bold numeric">
                          {{ bag.name }}
                        </td>
                        <td class="black12bold numeric ">
                          <div class="qty-in-dec" style="display: inline-flex">
                            <button type="button" class="minus-cart" @click="deductQty(bag)">
                              <i class="fa fa-minus"></i>
                            </button>

                            <div style="margin: 0px 2px">{{ bag.qty }}x{{ bag.amount / bag.qty }}</div>
                            <button :disabled="bag.product_price[0].quantity === $store.getters.prodQty(bag.id)"
                              type="button" class="plus-cart" @click="addQty(bag)">
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </td>
                        <td class="black12bold numeric">
                          {{ bag.amount }}
                        </td>

                      </tr>
                    </table>
                  </div>

                  <table width="100%">
                    <tr>
                      <td class="numeric" colspan="6" align="right">
                        <div style=" height:5px;"></div>
                      </td>
                    </tr>
                    <tr>
                      <td data-title="Subtotal" class="numeric" colspan="6" align="right">
                        <span class="black12bold" style="float:none;">Sub Total:&nbsp;&nbsp;&nbsp;&nbsp;BDT {{ total
                          }}.00</span>
                      </td>
                      <td class="black12normal dottedborder padding5 numeric" width="10%">&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="numeric" colspan="6" align="right" id="shippingvalue">
                      </td>
                      <td class="black12normal dottedborder padding5 numeric" width="10%">&nbsp;</td>
                    </tr>


                    <tr>
                      <td class="numeric" colspan="5">


                        <!--<button class="btn btn-sm btn-primary btn_font" class="close"
                                data-dismiss="modal" aria-label="Close">Continue Shopping
                        </button>-->


                        <!--input type="button" class="btn btn-sm btn-primary btn_font" value=" Go to Homepage " onclick="javascript:window.location='home/';" /-->


                      </td>
                      <td class="numeric" align="center" valign="middle" colspan="2">
                        <input type="button" class="btn btn-sm btn-primary btn_font" @click="goTOCheckOut"
                          value=" Check Out" />
                      </td>
                    </tr>
                  </table>
                </div>
              </form>
            </div>
            <div class="clr"></div>
          </div>


        </div><!-- modal-content -->

      </div><!-- modal-dialog -->

    </div><!-- modal -->
    <!---------------------------------------------Cart list modal------------------------------------------------------------------->

    <!---------------------------------------------Product Details modal------------------------------------------------------------------->

  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: 'BrandMenu',
  props: {
    msg: String
  },
  data() {
    return {
      productSearch: '',
      searchItem: false
    };
  },
  computed: {
    ...mapGetters([
      "shoppingBag", "total", "accessToken", "customer", "productBySearch"
    ]),
  },
  mounted() {
    this.$store.dispatch('getProductBySearchClear', 0);
    this.productSearch = '';
  },
  watch: {
    productSearch(val) {
      if (val.length > 2) {
        this.searchItem = true;
        this.$store.dispatch('getProductBySearch', val);
      } else if (val.length < 3) {
        this.searchItem = false;
        this.$store.dispatch('getProductBySearchClear', val);
      }
    },
    $route(newVlue, oldValue) {
      if (newVlue.fullPath !== oldValue.fullPath) {
        this.$store.dispatch('getProductBySearchClear', 0);
        this.productSearch = '';
        this.searchItem = false;
      }
    },
    shoppingBag(val) {
      if (val.length === 0) {
        this.$store.dispatch('hideCart')
      }
      let allData = []
      let mData = {
        item_name: '',
        item_id: '',
        price: '',
        item_brand: '',
        item_category: '',
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_variant: '',
        item_list_name: '',
        item_list_id: '',
        index: 0,
        quantity: '',
      }
      this.shoppingBag.forEach(item => {
        mData.item_name = item.name
        mData.item_id = item.id
        mData.price = item.unitPrice
        mData.item_brand = item.brand_id
        mData.quantity = item.qty
        allData.push(mData)
      })
      if (val.length > 0) {
        window.fbq('track', 'AddToCart', { value: 0, currency: 'BDT', contents: allData, content_type: "product" })
      }

    }
  },
  methods: {
    deductQty(product) {
      if (this.$store.getters.prodQty(product.id) > 0) {
        this.$store.dispatch("deductQty", product);
      }
      if (this.$store.getters.prodQty(product.id) == 0) {
        this.$store.dispatch("removeFromCart", product);
      }
      // if (product.qty <= product.product_price[0].quantity) {
      //   if (
      //       product.qty >= product.product_price[0].tier_quantity_three &&
      //       product.product_price[0].tier_quantity_three !== 0
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_three;
      //   } else if (
      //       product.qty >= product.product_price[0].tier_quantity_two &&
      //       product.qty < product.product_price[0].tier_quantity_three
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_two;
      //   } else if (
      //       product.qty >= product.product_price[0].tier_quantity_one &&
      //       product.qty < product.product_price[0].tier_price_two
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_one;
      //   } else if (product.product_price[0].offer_price) {
      //     this.proPrice = product.product_price[0].offer_price;
      //   } else {
      //     this.proPrice = product.product_price[0].price;
      //   }
      // }
    },
    addQty(product) {
      // console.log('update', product.product_price[0].tier_quantity_three)
      this.$store.dispatch("addQty", product);
      // if (product.qty <= product.product_price[0].quantity) {
      //   if (
      //       product.qty >= product.product_price[0].tier_quantity_three &&
      //       product.product_price[0].tier_quantity_three !== 0
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_three;
      //   } else if (
      //       product.qty >= product.product_price[0].tier_quantity_two &&
      //       product.qty < product.product_price[0].tier_quantity_three
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_two;
      //   } else if (
      //       product.qty >= product.product_price[0].tier_quantity_one &&
      //       product.qty < product.product_price[0].tier_price_two
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_one;
      //   } else if (product.product_price[0].offer_price) {
      //     this.proPrice = product.product_price[0].offer_price;
      //   } else {
      //     this.proPrice = product.product_price[0].price;
      //   }
      // }
    },
    logout() {
      this.$store.dispatch("logout");
      //window.localStorage.clear();
      this.$router.push({ name: 'home' }).catch(() => {
      });
      location.reload();
    },
    goToSearchPage(searchVal) {
      if (searchVal) {
        this.$router.push('/search/' + searchVal).catch(() => {
        })
      }

    },
    goTOCheckOut() {
      //const el = document.querySelector('#bodyWrap');
      //el.classList.remove("active");
      this.$store.dispatch('hideCart');
      this.$router.push('/checkout').catch(() => {
      })
      // if (this.accessToken) {
      //   this.$router.push('/checkout').catch(() => {
      //   })
      // } else {
      //   this.$router.push({name: 'login'}).catch(() => {
      //   })
      // }

    },
  },
}
</script>
<style>
.right-fixed-cart a {
  color: #fff;
  text-align: center;
}

p.right-fixed-cart-total {
  margin: 0;
  font-size: 15px;
  padding: 5px 0px;
  color: #fff;
  font-weight: bold;
}

.right-fixed-cart {
  position: fixed;
  color: #fff;
  right: 0;
  top: 48vh;
  background: red;
  font-size: 20px;
  padding: 20px 7px;
  text-align: center;
  border-radius: 15px 0px 0px 15px;
  cursor: pointer;
}

.search-item {
  height: 300px;
  text-align: left;
  background: #fff;
  overflow-y: auto;
  position: absolute;
  z-index: 1;
  width: 97%;
}

.search-item ul {
  padding: 5px;
}

.search-item ul li a {
  color: #000;
}

.search-item ul li a:hover {
  color: #fff;
  background: #000
}

button.plus-cart,
button.minus-cart {
  padding: 0;
  border-radius: 3px;
}

i.fa.fa-minus,
i.fa.fa-plus {
  color: #010101;
  padding: 1px 5px;
  font-size: 8px;
}

div#no-more-tables td {
  padding: 7px 0px;
  border-bottom: 1px solid #ddd;
}
</style>