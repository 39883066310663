<template>
  <section>
    <div v-if="siteSettings.length > 0" class="footer clearfix">

      <div class="container">

        <div class="row">

          <div class="col-md-3 col-sm-6 col-xs-12">

            <div class="footerLink">
              <h5 style="text-transform: uppercase">{{ siteSetting("our-company-2").title }}</h5>
              <!--                            <h5>INFORMATION</h5>-->

              <!-- <ul class="list-unstyled"> <li><a href="#"></a></li> </ul> -->

              <ul class="list-unstyled">
                <li v-for="details in siteSetting('our-company-2').site_details" :key="details.id">
                  <router-link :to="{ name: 'pages', params: {slug: details.url},}">{{ details.title }}</router-link>
<!--                  <a :href="details.url">{{ details.title }}</a>-->
                </li>
              </ul>

            </div>

          </div>

          <div class="col-md-3 col-sm-6 col-xs-12">

            <div class="footerLink">

              <h5 style="text-transform: uppercase">{{ siteSetting("quick-link-2").title }}</h5>

              <!-- <ul class="list-unstyled"> <li><a href="#"></a></li> </ul> -->

              <ul class="list-unstyled">

                <li
                    v-for="details in siteSetting('quick-link-2').site_details"
                    :key="details.id"
                >
                  <router-link :to="{ name: 'pages', params: {slug: details.url},}">{{ details.title }}</router-link>
<!--                  <a :href="details.url">{{ details.title }}</a>-->
                </li>
              </ul>

            </div>

          </div>

          <div class="col-md-3 col-sm-6 col-xs-12">

            <div class="footerLink">

              <h5 style="text-transform: uppercase">{{ siteSetting("download-our-app-2").title }}</h5>

              <ul v-if="siteSetting('download-our-app-2').site_details.length > 0" class="list-unstyled">
                <li v-for="details in siteSetting('download-our-app-2').site_details" :key="details.id">
                  <a target="_blank" :href="details.url">
                    <img v-bind:src="'https://eurasiasupplies.com/euadmin/storage/images/setting/' + details.image" alt=""
                         height="68px"/>
                  </a>
                  <br>
                </li>

              </ul>

            </div>

          </div>

          <div class="col-md-3 col-sm-6 col-xs-12">

            <div class="footerLink">

              <h5 style="text-transform: uppercase">{{ siteSetting("contact-us-2").title }}</h5>

              <ul v-if="siteSetting('contact-us-2').site_details.length > 0" class="list-unstyled">

                <li
                    v-for="details in siteSetting('contact-us-2').site_details.filter(sl => sl.short_info !== null )"
                    :key="details.id"
                >
                  {{ details.short_info }},
                </li>

              </ul>

            </div>

          </div>

        </div>

      </div>

    </div>
    <!--Old FOOTER-->
    <div class="copyRight clearfix">

      <div class="container">

        <div class="row">

          <div class="col-xs-12 text-center">

            <p class="text-center">&copy; {{ new Date().getFullYear() }} Copyright EURASIA SUPPLIES</p>

          </div>

        </div>

      </div>

    </div>
  </section>

</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'FooterMenu',
  props: {
    msg: String
  },
  computed: {
    ...mapGetters(["siteSettings","siteSetting"]),
  },
}
</script>