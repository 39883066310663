<template>
    <!--TOPBAR-->

    <div class="header_container_top">

        <div class="container">

            <ul>

                <li><a href="https://eurasiasupplies.com/cash-on-delivery" title="Cash on Delivery">Cash on Delivery</a></li>

                <li><a href="https://eurasiasupplies.com/how-to-buy" title="How to Buy">How to Buy</a></li>

            </ul>

            <div class="f-left">

                <div class="hotline">

                    <p>Hotline : <b>01889841414, 01889841415, 01312880808</b></p>

                    <p></p>

                </div>

            </div>

        </div>

    </div>
</template>
<script>
    export default {
        name: 'InfoMenu',
        props: {
            msg: String
        }
    }
</script>