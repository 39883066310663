<template>
    <!--New Footer-->
    <div class="footer clearfix">

        <div class="container">

            <div class="row">

                <br>

                <div class="col-md-3 col-sm-6 col-xs-12">

                    <div class="footerLink">

                        <p class="MsoNormal"><span style="color: #ffffff; font-family: arial, helvetica, sans-serif;">Eurasiasupplies.com.bd is the best e-commerce site in Bangladesh which offers directly imported premium quality products from UK, USA, Canada, Italy, Thailand, China, Malaysia, Singapore, Dubai, Australia and many more countries.</span></p>
                        <p class="MsoNormal"><span style="color: #ffffff; font-family: arial, helvetica, sans-serif;">We are selling thousands of products with home delivery service across Bangladesh. Our online shop is offering a completely hassle-free shopping experience through a secure and trusted source. Eurasiasupplies.com.bd is your one-stop corner for every kind of beauty products, cosmetics, SkinCare, Makeup Kits etc. We tend only display the products we have in stock so that we can deliver it in the shortest possible time.</span></p>
                        <p><span style="font-family: arial, helvetica, sans-serif;"><span style="color: #ffffff;">&nbsp;</span><span style="color: #ffffff;">Eurasiasupplies.com.bd is providing you the greatest online shopping experience. Here you can find all kinds of luxurious beauty products. Now your shopping experience is easier and completely hassle-free. you can find the best trends in fashion according to seasons and occasions with Eurasiasupplies online shopping facilities and remain in style 24x7 and 365 days a year.&nbsp;</span></span></p>
                    </div>

                </div>

                <div class="col-md-3 col-sm-6 col-xs-12">

                    <div class="footerLink">

                        <p class="MsoNormal"><span style="color: #ffffff; font-size: medium;"><strong>TRENDING OFFERS</strong></span></p>
                        <p class="MsoNormal"><span style="color: #ffffff;">Keeping in mind our tradition as Bangladeshi we try to create exclusive offers for our customers for holidays and festivals so that they can be at their best.</span></p>
                        <p class="MsoNormal"><span style="color: #ffffff;">Deals Under 999, Top Up &amp; eStore, Valentines Day Sale, Pohela Boishakh Sale, 11 11 Sale, 12 12 SalePUJA SALE, deals Of The Week, Eid Shopping Fest, Fashion Week, Fatafati Friday Deals, Grocery Mohotsshob, Masher Bazar Sale,&nbsp; Pohela Boishakh,&nbsp; Voucher Fireworks.</span><br /><br /><span style="color: #ffffff;">we look forward to revolutionizing online shopping by providing the best goods and customer service than our competitors.100% authentic products and consumer satisfaction is the goal.<br /></span></p>
                        <p class="MsoNormal"><span style="color: #ffffff; font-size: medium;"><strong>Top Brands We work with :</strong></span></p>
                        <p class="MsoNormal"><span style="color: #ffffff;"><span style="font-family: Calibri, sans-serif; font-size: 11pt;">The Body Shop,Mac,Nior,Dior,Jordana,Clinique, Himalayan, Camomile, Olay,Rimmel, johnsons, GARNIER, Biore, Simple, NIP+FAB, FCUK, Super Drag, botanical lab, NYX, Makeup Revolution London, NYX, W7, GILLETTE,</span>&nbsp;</span></p>
                    </div>

                </div>

                <div class="col-md-3 col-sm-6 col-xs-12">

                    <div class="footerLink">

                        <p class="MsoNormal"><span style="font-family: Calibri, sans-serif; font-size: 11pt; color: #ffffff;">&nbsp;Nivea, L.A.GIRL, Mac, Maybelline, BEYONCE Heat,Colourpop, Davidoff, DIOR,DKNY, Dunhill, Milani, Medipure, Morrisons, Ferrari, Giourgio, Arman, Givenchy, Guess, Head &amp; Shoulders, Herbal Essences, Hugo Boss, Iceberg, Instinct, Isse Miyake, Jaguar, Jean-paul , Jeanne Arthes, Jimmy Choo, Gillette Venus, Jordana, Lacoste, Lancome, Marks &amp; Spencer, Maybelline, Milani, Morrisons, Montblanc, MUA, Neutral, ON optimum nutrition, oral-b, Paco Rabanne, Palmers, Pampers ,Pierre Cardin , Polo, Roca, Sensodyne,&nbsp; Signal, Veet, Simple, ST. Ives, Super Drag, Thierry Muglar, Tommy Hilfiger, Tresemme, Vaseline, Victoria-Secret, Vita Coco, Wave 2, XHC, Xpel, xs.&nbsp;</span></p>
                        <p class="MsoNormal"><strong style="color: #ffffff; font-size: medium;">Top Categories&nbsp;</strong></p>
                        <p class="MsoNormal"><span style="color: #ffffff; font-size: small;"><strong>Lip &amp; MAKEUP:</strong></span></p>
                        <p><span style="color: #ffffff;">Rimmel, johnsons, NYX, Makeup Revolution London, W7, L.A.GIRL, Mac, Maybelline, BEYONCE Heat, Colour pop, Davidoff, DIOR, Milani, Jordana, MUA,<span style="font-size: 11pt; line-height: 115%; font-family: Calibri, sans-serif;">&nbsp;</span></span></p>
                    </div>

                </div>

                <div class="col-md-3 col-sm-6 col-xs-12">

                    <div class="footerLink">

                        <p class="MsoNormal"><strong style="color: #ffffff; font-size: small;">SKINCARE:</strong></p>
                        <p class="MsoNormal"><span style="color: #ffffff;">Clinique, Himalayan, Camomile, Olay, GILLETTE, Nivea, Neutral, botanical lab, Palmers, Veet, ST. Ives, Vaseline, Vita Coco,</span></p>
                        <p class="MsoNormal"><span style="color: #ffffff;">HAIR Care: Head &amp; Shoulders, Herbal Essences, Garnier, Tresemme, XHC, Wave 2, xs.</span></p>
                        <p class="MsoNormal"><span style="color: #ffffff;">PERSONAL CARE: FCUK, DKNY, Dunhill, , , Morrisons, Ferrari, Giourgio, Arman, Givenchy, Guess, , Hugo Boss, , Iceberg, Instinct, Isse Miyake, Jaguar, Jean-paul , Jeanne Arthes, Jimmy Choo, Gillette Venus, , Lacoste, , Lancome, oral-b, Paco Rabanne, Sensodyne, Signal, Pierre Cardin , Polo, Roca, Thierry Muglar, Tommy Hilfiger, Victoria-Secret,</span></p>
                        <p class="MsoNormal"><span style="color: #ffffff;">NATURAL:&nbsp;GARNIER, Biore, Simple, NIP+FAB, Super Drag, botanical lab, Marks &amp; Spencer, ST. Ives, Xpel,</span></p>
                        <p class="MsoNormal"><span style="color: #ffffff;">MOM &amp; BABY: Medipure, Palmers, Pampers,</span></p>
                        <p><span style="color: #ffffff;">Sports Nutrition: ON optimum nutrition,</span></p>
                    </div>

                </div>

            </div>

        </div>

    </div>
</template>
<script>
    export default {
        name: 'FooterTopDescription',
        props: {
            msg: String
        }
    }
</script>