<template>
  <!--Follow us Start-->
  <div v-if="siteSettings.length > 0" class="footer clearfix">

    <div class="container">

      <div class="row">

        <br>

        <div class="col-md-4">

          <div class="footerLink">

            <center>

              <h3>{{ siteSetting("follow-us-2").title }}</h3>
              <ul class="list-inline">
                <li v-for="details in siteSetting('follow-us-2').site_details" :key="details.id">
                  <a target="_blank" :href="details.url">
                    <img v-bind:src="'https://eurasiasupplies.com/euadmin/storage/images/setting/' + details.icon"
                      width="35px" height="auto" alt="" />
                  </a>
                </li>

              </ul>

            </center>

          </div>

        </div>

        <div class="col-md-4">

          <div class="footerLink">

            <center>

              <h3>Sign up for our newsletter</h3>

              <form class="" @submit="newsletter">

                <div class="input-group">
                  <input name="news" v-model="news.email" class="form-control" placeholder="example@gmail.com"
                    type="email">
                  <span class="input-group-btn">
                    <button name="search" class="btn-news" type="submit"><i class="fas fa-paper-plane"></i></button>
                  </span>
                </div>

              </form>

            </center>

          </div>

        </div>

        <div class="col-md-4">
          <div class="footerLink payment-option">
            <center>
              <h3>{{ siteSetting("accept-payment-options-2").title }}</h3>
              <img v-for="details in siteSetting('accept-payment-options-2').site_details" :key="details.id"
                v-bind:src="'https://eurasiasupplies.com/euadmin/storage/images/setting/' + details.image" alt="" />
            </center>
          </div>

        </div> &nbsp;
      </div>

    </div>

  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: 'FooterSocial',
  data() {
    return {
      news: {
        email: "",
      },
      showError: false,
    };
  },
  props: {
    msg: String
  },
  computed: {
    ...mapGetters(["siteSettings", "siteSetting", "getnewsletter"]),
  },
  methods: {
    newsletter(e) {
      e.preventDefault();
      //// console.log(this.news);
      this.$store.dispatch("news", this.news);
      this.news.email = "";
      // console.log("-----", this.getnewsletter)
      this.$swal({
        text: this.getnewsletter,
        confirmButtonColor: '#ff1d1d'
      });
    },
  },
}
</script>
<style>
.footerLink.payment-option img {
  height: 35px;
  display: inline;
  margin-right: 5px;
}
</style>